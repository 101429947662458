<template>
<div class="upload-single">

</div>
</template>

<script>
export default {
  name: "uploadSingleData.vue"
}
</script>

<style scoped lang="scss">
.upload-single{

}
</style>
