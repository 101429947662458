<template>
  <div class="test-publish">
    <div class="table">
      <Table :columns="columns" :data="dataList" ></Table>
    </div>
      <div class="page">
        <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>

    <div class="bot">
      <Button type="primary" @click="pre">{{ $t('trainingcamp_exam_create_pre') }}</Button>
      <Button @click="save" :loading="saveLoading">{{ $t('trainingcamp_exam_create_save') }}</Button>
<!--      <Button @click="publish" :loading="publishLoading" >发布</Button>-->
    </div>
    <DeleModal :status="deleModalStatus" txt="确认删除该题吗？" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
  </div>
</template>

<script>
import DeleModal from '@/components/deleteModal.vue';
export default {
  name: "testPublish",
  data(){
    return {
      columns: [
        {
          title: this.$t('trainingcamp_exam_create_publish_index'),
          type:'index',
          width:60,
        },
        {
          title: this.$t('trainingcamp_exam_create_publish_title'),
          key: 'name',
          render: (h, params) => {
            return h('span',params.row.data.name)
          }
        },
        {
          title: this.$t('trainingcamp_exam_create_publish_type'),
          key: 'type',
          width:120,
          render: (h, params) => {
            return h('span',this.typesObj[params.row.data.type])
          }
        },
        {
          title: this.$t('trainingcamp_exam_create_publish_level'),
          key: 'level',
          width:120,
          render: (h, params) => {
            return h('span',this.levelsObj[params.row.data.level])
          }
        },
        {
          title: this.$t('trainingcamp_exam_create_publish_op'),
          width:160,
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                },
                on: {
                  click: () => {
                    // this.dataForbidden(params.row.id,'0');
                    this.deleModalStatus = true;
                    this.curData = params.row;
                    this.deleteData();
                  }
                }
              }, '删除')
            ]);
          }
        }
      ],
      dataList: [],
      type:'',
      level:'',
      status:'',
      loading:false,
      page:1,
      pageSize:10,
      levelsObj:{},
      typesObj:{},
      levelsList:[],
      typesList:[],
      trainDataStatusesObj:{},
      trainDataStatusesList:[],
      deleModalStatus:false,
      modalLoading:false,
      curData:{},
      total:0,
      saveLoading:false,
      publishLoading:false,
    }
  },
  components:{
    DeleModal
  },
  props:{
    categoryId:{
      type:String,
      default:'',
    },
    importBatch:{
      type:String,
      default:'',
    }
  },
  created(){
    this.getList();
  },
  methods:{
    deleteData(){
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let params = {
        category_id:this.categoryId,
        data_id:this.curData.data_id,
      };
      this.modalLoading = true;
      this.api.dataset.datasetCategoryDataDelete(params).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList()

      }).catch((err)=>{
        this.modalLoading = false;
      })
    },
    getList(){
      let params = {
        site_id:-1,
        user_id:-1,
        category_id:this.categoryId,
        page_size:this.pageSize,
        page:this.page,
        import_batch:this.importBatch
      };
      this.loading = true;
      this.api.dataset.categoryDataList(params).then((res)=>{
        this.loading = false;
        this.dataList = res.list;
        this.levelsObj = res.data_levels;
        this.levelsList = this.formatData(this.levelsObj);
        this.typesObj = res.data_types;
        this.typesList = this.formatData(this.typesObj);
        this.trainDataStatusesObj = res.statuses;
        this.trainDataStatusesList = this.formatData(this.trainDataStatusesObj);

        this.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    formatData(data){
      let arr = [];
      for(let name in data){
        arr.push({
          label:data[name],
          value:name
        });
      }
      return arr;
    },

    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.getList();
    },
    pre(){
      this.$emit('pre');
    },
    save(){
      this.submit('save')
    },
    publish(){
      this.submit('publish')
    },
    submit(type){
      let params = {
        id:this.categoryId
      };
      if(type == 'save'){
        this.saveLoading = true;
      }else if(type == 'publish'){
        this.publishLoading = true;
      }
      this.api.dataset.datasetCategoryRelease(params).then((res)=>{
        if(type == 'save'){
          this.saveLoading = false;
        }else if(type == 'publish'){
          this.publishLoading = false;
        }
        this.$Message.success('操作成功');
        setTimeout(()=>{
          this.$router.go(-1)
        },2000)
      }).catch((err)=>{
        if(type == 'save'){
          this.saveLoading = false;
        }else if(type == 'publish'){
          this.publishLoading = false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.test-publish{
  .top{
    display: flex;
    justify-content: flex-start;
    >div{
      margin-right: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        padding-right: 5px;
      }
    }
  }
  .op-btn{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        margin-right: 20px;
        >span{
          color:#2d8cf0;
        }
      }
      >button{
        margin-right: 20px;
      }
    }
  }
  .table{
    margin-top: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
  .bot{
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #dcdee2;
    display: flex;
    justify-content: flex-end;

    >button{
      margin-left: 10px;
    }
  }

}
.add-questions{
  .top{
    display: flex;
    justify-content: flex-start;
    >div{
      margin-right: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        padding-right: 5px;
      }
    }
  }
  .select-num{
    margin-top: 20px;
    >span{
      padding: 0 5px;
      color:#2d8cf0;
    }
  }
  .table{
    margin-top: 20px;
  }
  .page{
    margin: 20px 0;
    text-align: right;
  }
}

</style>
