<template>
<div class="upload-data">
  <div class="upload-data-top">
    <div class="top-cont">
      <div :class="curTabIndex == index ? 'active' : ''" v-for="(item,index) in tabList" :key="item.id" @click="changeTab(index)">
        {{ item.name }}</div>
    </div>
  </div>
  <component :is="tabList[curTabIndex].componentName" :categoryId="categoryId" @uploadFile="uploadFileSucc"></component>
  <div class="bot">
    <Button type="primary" @click="pre">{{ $t('trainingcamp_exam_create_pre') }}</Button>
<!--    <Button @click="save" :loading="saveLoading" >{{ $t('trainingcamp_exam_create_save') }}</Button>-->
    <Button type="primary" @click="next" :loading="nextLoading">{{ $t('trainingcamp_exam_create_next') }}</Button>
  </div>
</div>
</template>

<script>
import UploadExcel from './uploadExcel';
import UploadSingleData from './uploadSingleData';
export default {
  name: "uploadData.vue",
  data(){
    return {
      tabList:[
        {
          name:'文档导入',
          id:1,
          componentName:'UploadExcel'
        },
        // {
        //   name:'单题导入',
        //   id:2,
        //   componentName:'UploadSingleData'
        // },
      ],
      curTabIndex:0,
      uploadfileId:'',
      nextLoading:false,
      saveLoading:false,
    }
  },
  props:{
    categoryId:{
      type:String,
      default:'',
    }
  },
  components:{
    UploadExcel,
    UploadSingleData
  },
  methods:{
    changeTab(index){
      this.curTabIndex = index;
    },
    pre(){
      this.$emit('pre');
    },
    save(){
      this.submit('save')
    },
    next(name,type){
      this.submit('next')
    },
    uploadFileSucc(data){
      this.uploadfileId = data;
      console.log(this.uploadfileId,'this.uploadfileId');
    },
    submit(type){
      if(!this.uploadfileId){
        this.$Message.warning('请上传文件');
        return;
      }
      let params = {
        category_id:this.categoryId,
        uploadfile_id:this.uploadfileId
      };
      if(type == 'next'){   //下一步
        this.nextLoading = true;
      }else if(type == 'save'){ //保存并退出
        this.saveLoading = true;
      }
      this.api.dataset.datasetCategoryUploadfile(params).then((res)=>{
        if(type == 'next'){   //下一步
          this.nextLoading = false;
          this.$emit('next',res.import_batch);
          //res.data.import_batch;
        }else if(type == 'save'){ //保存并退出
          this.saveLoading = false;
          this.$emit('save');
        }
      }).catch((err)=>{
        if(type == 'next'){   //下一步
          this.nextLoading = false;
        }else if(type == 'save'){ //保存并退出
          this.saveLoading = false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.upload-data{
  .upload-data-top{
    display: inline-block;
    .top-cont{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      line-height: 40px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      overflow: hidden;

      >div{
        width: 100px;
        text-align: center;
        border-right: 1px solid #dcdee2;
      }
      >div:last-child{
        border-right:none;
      }
      .active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }
  }
  .bot{
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #dcdee2;
    display: flex;
    justify-content: flex-end;

    >button{
      margin-left: 10px;
    }
  }
}
</style>
