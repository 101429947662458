var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"upload-excel"},[_c('div',{staticClass:"tit"},[_vm._v("模板导入试题")]),_c('div',{staticClass:"tip"},[_vm._v("请先下载格式模板，参考模板，调整自己的文档格式，再进行上传")]),_c('Upload',{staticClass:"upload-cont",attrs:{"type":"drag","action":_vm.constant.URL + '/uploadfile/upload',"headers":{
                  'access-token':_vm.Cookies.get(_vm.constant.tokenName)
                },"data":{
                  type:0
                },"accept":".xls,.xlsx,.csv","format":['xls','xlsx','csv'],"on-success":_vm.fileSuccess,"show-upload-list":false,"on-format-error":_vm.formatError}},[_c('div',{staticClass:"upload-cont-info"},[_c('img',{attrs:{"src":require("../../../../assets/images/icon/excel.png")}}),_c('p',[_vm._v(_vm._s(_vm.fileName ? _vm.fileName : 'Excel模板导入'))]),_c('div',{staticClass:"upload-cont-info-message"},[_c('p',[_vm._v("1. 支持图片上传，图片放在单元格以内。")]),_c('p',[_vm._v("2. 不支持公式。")]),_c('p',[_vm._v("3. 按照模板格式，编辑输入试题文档。")]),_c('p')])])]),_c('div',{staticClass:"upload-btn"},[_c('Button',{on:{"click":_vm.downLoadExcel}},[_vm._v("Excel模板下载")]),_c('Upload',{attrs:{"action":_vm.constant.URL + '/uploadfile/upload',"headers":{
                  'access-token':_vm.Cookies.get(_vm.constant.tokenName)
                },"data":{
                  type:0
                },"accept":".xls,.xlsx,.csv","on-success":_vm.fileSuccess,"show-upload-list":false}},[_c('Button',{attrs:{"icon":"ios-cloud-upload-outline","type":"primary"}},[_vm._v("上传题库")])],1)],1),_c('div',{staticClass:"tip"},[_vm._v("点击按钮选择试题文件，或拖拽试题文件到此区域")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }