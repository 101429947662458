<template>
  <div class="test-create">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="test-create-cont">
      <div class="test-create-cont-top" v-if="curIndex || curIndex== '0'">
        <div class="item" :class="tabList[curIndex].id == item.id ? 'active' : ''" v-for="(item,index) in tabList" :key="item.id" @click="changeTab(index)">
          <div class="l">
            {{index + 1}}
          </div>
          <div class="r">
            <p>{{item.name}}</p>
            <p>{{item.subName}}</p>
          </div>
        </div>
      </div>
      <div class="cont">
        <div class="cont-com" v-if="curIndex || curIndex== '0'">
          <component :is="tabList[curIndex].componentName" :categoryInfo="categoryInfo" :categoryId="categoryId" :importBatch="importBatch" @save="save" @next="next" @pre="pre"></component>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import Info from './components/group/info'
import UploadDate from './components/group/uploadData'
import DataList from './components/group/dataList'
export default {
  name: "testPapetCreate",
  data(){
    return {
      tabList:[
        // {
        //   name:'步骤1',
        //   subName:'基本信息',
        //   id:1,
        //   componentName:'Info',
        // },
        {
          name:'步骤1',
          subName:'上传数据',
          id:1,
          componentName:'UploadDate',
        },
        {
          name:'步骤2',
          subName:'用法说明',
          id:2,
          componentName:'DataList',
        }
      ],
      curIndex:'0',
      // categoryId:'',
      categoryId:'',
      isEdit:false,
      path:'/trainingcamp/mygroup',
      categoryInfo:{},
      importBatch:'',
    }
  },
  components:{
    // Info,
    UploadDate,
    DataList
  },
  created(){
    this.categoryId = this.$route.query.id;
    this.getDetail();
  },
  methods:{
    getDetail(type){
      let params = {
        id:this.categoryId
      };
      this.api.dataset.categoryDetail(params).then((res)=>{
        this.categoryInfo = res.info;
      })
    },
    changeTab(index){
      if(!this.importBatch && index == 1){
        this.$Message.warning('请先上传文件');
        return;
      }
      this.curIndex = index + '';
    },
    pre(){
      this.getDetail('pre');
      this.curIndex--;
    },
    next(data){
      this.curIndex++;
      console.log(data,"-=-=-=-=-=-=-=");
      this.importBatch = data;
    },
    save(){ //跳转列表页
      this.$router.push({
        path:this.path
      })
    },
    publish(){  //跳转列表页
      this.$router.push({
        path:this.path
      })
    }
  }
}
</script>

<style scoped lang="scss">
.test-create{
  margin: 20px;

  .test-create-cont{
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
  }

  .test-create-cont-top{
    height: 80px;
    //line-height: 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border:1px solid #dcdee2;
    border-radius: 6px;
    overflow: hidden;

    >div{
      flex: 1;
      text-align: center;
      border-right: 1px solid #dcdee2;
      font-size: 14px;
      cursor:pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .l{
        margin-right: 10px;
        font-size: 40px;
      }
      .r{
        text-align: left;
        >p:nth-child(1){
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    >div:last-child{
      border-right: none;
    }
    >div.active{
      background: #2d8cf0;
      color:#ffffff;
    }
  }
  .cont{
    .cont-com{
      margin-top: 20px;
    }
  }
}
</style>
